import { Grid, Paper, Typography, ButtonBase, Link } from '@material-ui/core';
import { GitHub } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme();

const useStyles = makeStyles({
  root: {
    marginTop: '2rem',
  },
  paper: {
    width: '75%',
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
  img: {
    margin: 'auto',
    display: 'block',
    width: '20rem',
  },
  clickableIcon: {
    cursor: 'pointer',
    margin: '1rem',
  },
});

function Projects() {
  const classes = useStyles();

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          {' '}
          <Grid container noWrap spacing={2}>
            <Grid item>
              <ButtonBase
                className={classes.image}
                onClick={() =>
                  window.open(`https://fiftyfiftyraffle.netlify.app/`, '_blank')
                }
              >
                <img className={classes.img} alt="Raffle" src="Raffle.png" />
              </ButtonBase>
            </Grid>
            <Grid item xs>
              <Typography gutterBottom variant="h6">
                <Link
                  href="https://github.com/jpc20/fifty-fifty"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  50/50 Raffle (Personal Project 2020)
                </Link>
              </Typography>
              <Typography variant="body2" gutterBottom>
                Raffle application built as a side project with NFT(ERC721)
                tickets deployed to Rinkeby testnet (Ethereum). Users with
                MetaMask can create raffles, purchase tickets, and distribute
                funds to a random ticket holder.
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Tech stack: React, Hardhat, Solidity, ethers.js, chai, jest,
                material-ui
              </Typography>
              <GitHub
                onClick={(event) =>
                  window.open('https://github.com/jpc20/fifty-fifty', '_blank')
                }
                className={classes.clickableIcon + ' githubButton'}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Projects;
