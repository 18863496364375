import { AppBar, Toolbar } from "@material-ui/core";
import { GitHub, LinkedIn, Twitter } from "@material-ui/icons";
import { Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
    alignItems: "center",
    marginTop: "1rem",
  },
  clickableIcon: {
    cursor: "pointer",
    margin: "1rem",
  },
}));
const Footer = () => {
  const classes = useStyles();

  return (
    <>
      <AppBar position="static" className={classes.appBar} color="inherit">
        <Toolbar>
          <Typography variant="subtitle1" gutterBottom>
            Contact me:{"  "}
            <Button
              onClick={() => window.open("mailto:jpcullen20@gmail.com")}
              color="inherit"
            >
              jpcullen20@gmail.com
            </Button>
          </Typography>
          <GitHub
            onClick={(event) =>
              window.open("https://github.com/jpc20", "_blank")
            }
            className={classes.clickableIcon + " githubButton"}
          />
          <LinkedIn
            onClick={(event) => {
              window.open(
                "https://www.linkedin.com/in/jack-cullen-/",
                "_blank"
              );
            }}
            className={classes.clickableIcon + " linkedInButton"}
          />
          <Twitter
            onClick={(event) =>
              window.open("https://twitter.com/jpcullen20", "_blank")
            }
            className={classes.clickableIcon + " twitterButton"}
          />
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Footer;
