import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import CryptoTable from './components/CryptoTable';
import Header from './components/Header';
import Projects from './components/Projects';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Divider } from '@material-ui/core';
import Footer from './components/Footer';

const theme = createTheme({
  palette: {
    type: 'dark',
  },
});

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  divider: {
    margin: '2rem',
  },
});

function App() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        <CryptoTable />
        <Divider className={classes.divider} />
        <Projects />
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
