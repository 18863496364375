import { Typography, Card, CardContent, CardMedia } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";

const theme = createTheme();

const useStyles = makeStyles({
  cardContent: {
    padding: "0 !important",
  },
  expandMore: {
    position: "absolute",
    transform: "translateX(-50%) translateY(-400%)",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
  },
  header: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%) translateY(-250%)",
    [theme.breakpoints.down("sm")]: {
      transform: "translateX(-30%) translateY(-100%)",
    },
  },
});

function Header() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <CardMedia component="img" image="/jackcullenheader.jpg" />
        <div className={classes.header}>
          <Typography variant="h2" gutterBottom>
            Jack Cullen
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Software Developer
          </Typography>
        </div>
        <ExpandMore fontSize="large" className={classes.expandMore} />
      </CardContent>
    </Card>
  );
}

export default Header;
