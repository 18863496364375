import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';

const useStyles = makeStyles({
  root: {
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '2rem',
  },
  table: {
    minWidth: 650,
  },
  nameCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  nameText: {
    marginRight: '1rem',
  },
  img: {
    width: '2rem',
    marginRight: '0.5rem',
  },
});

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

function CryptoTable() {
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetch(
      'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false',
      {
        headers: { accept: 'application/json' },
      }
    )
      .then((resp) => resp.json())
      .then((data) => setRows(data));
  }, []);

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="right">Price</TableCell>
            <TableCell align="right">Change</TableCell>
            <TableCell align="right">Market Cap</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(0, 10).map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {row.market_cap_rank}
              </TableCell>
              <TableCell align="left" className={classes.nameCell}>
                <img src={row.image} alt="table-img" className={classes.img} />{' '}
                <Typography variant="subtitle1" className={classes.nameText}>
                  {row.name}
                </Typography>
                <Typography variant="overline">
                  {row.symbol.toUpperCase()}
                </Typography>
              </TableCell>
              <TableCell align="right">
                {formatter.format(row.current_price)}
              </TableCell>
              <TableCell
                align="right"
                style={{
                  color: row.price_change_percentage_24h >= 0 ? 'green' : 'red',
                }}
              >
                {row.price_change_percentage_24h.toFixed(2) + '%'}
              </TableCell>
              <TableCell align="right">
                {formatter.format(row.market_cap)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CryptoTable;
